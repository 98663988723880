import { combineReducers } from 'redux'

import country from './Country'
import company from './Company'
import group from './Group'
import projectType from './ProjectType'
import plant from './Plant'
import classes from './Classes'
import channel from './Channel'
import taskComponent from './TaskComponent'
import employee from './Employee'
import member from './Member'
import productCategory from './ProductCategory'
import departement from './Departement'

const masterDataReducer = combineReducers({
    country,
    company,
    group,
    projectType,
    plant,
    classes,
    channel,
    taskComponent,
    employee,
    member,
    productCategory,
    departement
})

export default masterDataReducer