const initialState = {
    data: {}
}

const masterDataProjectTypeReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_MASTER_DATA_PROJECT_TYPE_DATA":
            return { ...state, data: action.data }
        default:
            return state
    }
}

export default masterDataProjectTypeReducer