import { combineReducers } from 'redux'
import masterData from './MasterData/masterDataReducer'
import ideaStage from './IdeaStage/ideaStageReducer'
import prototypeTrialStage from './PrototypeTrialStage/protoypeTrialStageReducer'
import factoryTrialStage from './FactoryTrialStage/factoryTrialStageReducer'
import manufacturingStage from './ManufacturingStage/manufacturingStageReducer'
import commercialStage from './CommercialStage/commercialStageReducer'
import setupConfiguration from './SetupConfiguration'
import userConfig from './UserConfig/userConfigReducer'
import report from './Report/reportReducer'

const rootReducer = combineReducers({
    masterData,
    ideaStage,
    prototypeTrialStage,
    factoryTrialStage,
    manufacturingStage,
    commercialStage,
    setupConfiguration,
    userConfig,
    report
})

export default rootReducer