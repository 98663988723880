const initialState = {
  data: {},
  details: {}
}

const CreateProjectReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_CREATE_PROJECT":
      return { ...state, data: action.data }
    case "GET_CREATE_PROJECT_DETAIL":
      return { ...state, details: action.data }
    default:
      return state
  }
}

export default CreateProjectReducer