import { combineReducers } from 'redux'

import ideaGeneration from './IdeaGeneration'
import createTemplate from './CreateTemplate'
import feasibilityStudy from './FeasibilityStudy'
import artworkPackagingDesign from './ArtworkPackagingDesign'
import createProject from './CreateProject'
import requestExpert from './RequestExpert'
import pdr from './PDR'

const ideaStageReducer = combineReducers({
    ideaGeneration,
    createTemplate,
    feasibilityStudy,
    artworkPackagingDesign,
    requestExpert,
    createProject,
    pdr
})

export default ideaStageReducer