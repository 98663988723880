import thunk from 'redux-thunk'
import createDebounce from 'redux-debounced'
import rootReducer from './reducer/rootReducer'
import { applyMiddleware, compose } from 'redux'
import { configureStore } from '@reduxjs/toolkit'

const middlewares = [thunk, createDebounce()]
const middlewareEnhancer = applyMiddleware(...middlewares)

const enhancers = [middlewareEnhancer]
const composedEnhancers = compose(...enhancers)

const store = configureStore({
    reducer: rootReducer,
    undefined,
    ...composedEnhancers
})

export default store

