const initialState = {
    main: {},
    details: {},
    options: {
        product_category: []
    }
}

const ArtworkPackagingDesignReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_IS_ARTWORK_PACKAGING_DESIGN_MAIN":
            return { ...state, main: action.data }
        case "GET_IS_ARTWORK_PACKAGING_DESIGN_DETAILS":
            return { ...state, details: action.data }
        case "GET_IS_APD_PRODUCT_CATEGORIES_OPTIONS":
            return { ...state, options: { ...state.options, product_category: action.data } }
        default:
            return state
    }
}

export default ArtworkPackagingDesignReducer