
const initialState = {
  data: [],
};

const masterDataProductCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_PRODUCT_CATEGORY":
      return { ...state, data: action.data };
    default:
      return state;
  }
};

export default masterDataProductCategoryReducer;
