const initialState = {
  data: {},
  details: {}
}

const CreateProjectReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_IS_CREATE_TEMPLATE":
      return { ...state, data: action.data }
    case "GET_IS_CREATE_TEMPLATE_DETAIL":
      return { ...state, details: action.data }
    default:
      return state
  }
}

export default CreateProjectReducer