import { combineReducers } from 'redux'

import cogm from './COGM'
import oemSourcing from './OEMSourcing'
import externalPanelData from './ExternalPanelData'
import pricingStructure from './PricingStructure'
import prototypeApproval from './PrototypeApproval'

const prototypeTrialStageReducer = combineReducers({
    cogm,
    oemSourcing,
    externalPanelData,
    pricingStructure,
    prototypeApproval
})

export default prototypeTrialStageReducer