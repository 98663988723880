const initialState = {
    main: {},
    details: {
        email_notification: false,
        modul: {},
        process: {},
        permission: {},
        must_all_approve_stage: []
    },
    options: {
        modul: [],
        process: [],
        permission: [],
        countries: [],
        productCategories: []
    }
}

const ApprovalListReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_UC_APPROVAL_LIST_MAIN":
            return { ...state, main: action.data }
        case "GET_UC_APPROVAL_LIST_DETAILS":
            return { ...state, details: action.data }
        case "GET_UC_APPROVAL_LIST_OPTIONS_MODUL":
            return { ...state, options: { ...state.options, modul: action.data } }
        case "GET_UC_APPROVAL_LIST_OPTIONS_PROCESS":
            return { ...state, options: { ...state.options, process: action.data } }
        case "GET_UC_APPROVAL_LIST_OPTIONS_PERMISSION":
            return { ...state, options: { ...state.options, permission: action.data } }
        case "GET_UC_APPROVAL_LIST_OPTIONS_COUNTRIES":
            return { ...state, options: { ...state.options, countries: action.data } }
        case "GET_UC_APPROVAL_LIST_OPTIONS_PRODUCT_CATEGORIES":
            return { ...state, options: { ...state.options, productCategories: action.data } }
        default:
            return state
    }
}

export default ApprovalListReducer