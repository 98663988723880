import { combineReducers } from 'redux'

import userList from './UserList'
import approvalList from './ApprovalList'
import roleList from './RoleList'
import permissionList from './PermissionList'

const userConfigReducer = combineReducers({
    userList,
    approvalList,
    roleList,
    permissionList
})

export default userConfigReducer