
const initialState = {
  data: [],
};

const masterDataClassesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_CLASSES":
      return { ...state, data: action.data };
    default:
      return state;
  }
};

export default masterDataClassesReducer;
