import { combineReducers } from 'redux'

import inhouseResultDashboard from './InhouseResultDashboard'
import oemResultDashboard from './OEMResultDashboard'

const manufacturingStageReducer = combineReducers({
    inhouseResultDashboard,
    oemResultDashboard
})

export default manufacturingStageReducer