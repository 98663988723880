const initialState = {
    main: {},
    details: {},
    options: {
        menu: []
    }
}

const PermissionListReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_UC_PERMISSION_LIST_MAIN":
            return { ...state, main: action.data }
        case "GET_UC_PERMISSION_LIST_DETAILS":
            return { ...state, details: action.data }
        case "GET_UC_PERMISSION_MENU_OPTIONS":
            return { ...state, options: { ...state.options, menu: action.data } }
        default:
            return state
    }
}

export default PermissionListReducer