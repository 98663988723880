const initialState = {
    main: {},
    details: {
        title: '',
        country_ids: [],
        product_category: { id: '', value: '' },
        product_sub_category: '',
        files: [],
        status: '',
        errors: []
    },
    options: {
        product_category: [],
        country: []
    }
}

const RequestExpertReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_IS_REQUEST_EXPERT_MAIN":
            return { ...state, main: action.data }
        case "GET_IS_REQUEST_EXPERT_DETAILS":
            return { ...state, details: action.data }
        case "GET_IS_REQUEST_EXPERT_OPTIONS_PRODUCT_CATEGORY":
            return { ...state, options: { ...state.options, product_category: action.data } }
        case "GET_IS_REQUEST_EXPERT_OPTIONS_COUNTRY":
            return { ...state, options: { ...state.options, country: action.data } }
        default:
            return state
    }
}

export default RequestExpertReducer