const initialState = {
    archieved: {
        records: [],
        total_row: 0
    },
    gantt: {
        project_titles: '',
        data: {}
    },
    board: {
        project_titles: '',
        data: {}
    },
    pieChart: {
        data: {},
        params: {},
        detail: []
    },
    box: {
        data: {},
        params: {}
    },
    options: {
        country: [],
        product_category: [],
        projects: [],
        members: [],
        pieChartMembers: []
    }
}

const ProjectReportReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_PROJECT_REPORT_ARCHIEVED":
            return { ...state, archieved: action.data }
        case "GET_PROJECT_REPORT_GANTT":
            return { ...state, gantt: action.data }
        case "GET_PROJECT_REPORT_BOARD":
            return { ...state, board: action.data }
        case "GET_PROJECT_REPORT_PIE_CHART":
            return { ...state, pieChart: { ...state.pieChart, data: action.data, params: action.params } }
        case "GET_PROJECT_REPORT_PIE_CHART_DETAIL":
            return { ...state, pieChart: { ...state.pieChart, detail: action.data } }
        case "GET_PROJECT_REPORT_BOX":
            return { ...state, box: { ...state.box, data: action.data, params: action.params } }
        case "GET_PROJECT_REPORT_OPTIONS_COUNTRIES":
            return { ...state, options: { ...state.options, country: action.data } }
        case "GET_PROJECT_REPORT_OPTIONS_PRODUCT_CATEGORIES":
            return { ...state, options: { ...state.options, product_category: action.data } }
        case "GET_PROJECT_REPORT_OPTIONS_PROJECTS":
            return { ...state, options: { ...state.options, projects: action.data } }
        case "GET_PROJECT_REPORT_OPTIONS_MEMBERS":
            return { ...state, options: { ...state.options, members: action.data } }
        case "GET_PROJECT_REPORT_PIE_CHART_OPTIONS_MEMBERS":
            return { ...state, options: { ...state.options, pieChartMembers: action.data } }
        default:
            return state
    }
}

export default ProjectReportReducer