const initialState = {
    main: {},
    details: {},
    options: {
        product_category: [],
        uom: [],
        project_title: []
    }
}

const OEMSourcingReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_PTS_OEM_SOURCING_MAIN":
            return { ...state, main: action.data }
        case "GET_PTS_OEM_SOURCING_DETAILS":
            return { ...state, details: action.data }
        case "GET_PTS_OEM_SOURCING_OPTIONS_PRODUCT_CATEGORY":
            return { ...state, options: { ...state.options, product_category: action.data } }
        case "GET_PTS_OEM_SOURCING_OPTIONS_UOM":
            return { ...state, options: { ...state.options, uom: action.data } }
        case "GET_PTS_OEM_SOURCING_OPTIONS_PROJECT_TITLES":
            return { ...state, options: { ...state.options, project_title: action.data } }
        default:
            return state
    }
}

export default OEMSourcingReducer