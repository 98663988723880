const initialState = {
    data: {},
    details: {},
    options: {
        company: [],
        menu: [],
        permission: []
    }
}

const RoleListReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_UC_ROLE_LIST_MAIN":
            return { ...state, data: action.data }
        case "GET_UC_ROLE_LIST_DETAILS":
            return { ...state, details: action.data }
        case "GET_UC_ROLE_COMPANY_OPTIONS":
            return { ...state, options: { ...state.options, company: action.data } }
        case "GET_UC_ROLE_MENU_OPTIONS":
            return { ...state, options: { ...state.options, menu: action.data } }
        case "GET_UC_ROLE_PERMISSION_OPTIONS":
            return { ...state, options: { ...state.options, permission: action.data } }
        default:
            return state
    }
}

export default RoleListReducer