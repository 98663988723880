const initialState = {
    main: {},
    options: {
        project_title: []
    }
}

const MonitoringApprovalReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_MONITORING_APPROVAL_MAIN":
            return { ...state, main: action.data }
        case "GET_MONITORING_APPROVAL_OPTIONS_PROJECT_TITLES":
            return { ...state, options: { ...state.options, project_title: action.data } }
        default:
            return state
    }
}

export default MonitoringApprovalReducer