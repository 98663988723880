const initialState = {
    details: {}
}

const ExternalPanelDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_PTS_EXTERNAL_PANEL_DATA_DETAILS":
            return { ...state, details: action.data }
        default:
            return state
    }
}

export default ExternalPanelDataReducer