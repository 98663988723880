const initialState = {
  data: [],
  details: {
    status: 1,
    company: {},
    companies: [],
  },
};

const masterDataUsersReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_USERS":
      return { ...state, data: action.data };
    case "GET_DETAIL_USERS":
      return { ...state, details: action.data };
    case "SET_DETAIL_USERS":
      return { ...state, details: action.data };
    default:
      return state;
  }
};

export default masterDataUsersReducer;
