import { combineReducers } from 'redux'

import monitoringApproval from './MonitoringApproval'
import projectReport from './ProjectReport'

const reportReducer = combineReducers({
    monitoringApproval,
    projectReport
})

export default reportReducer