const initialState = {
    main: {},
    options: {
        sc_field: [],
        sc_menu: []
    }
}

const SetupConfigurationReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_SETUP_CONFIGURATION_MAIN":
            return { ...state, main: action.data }
        case "GET_SC_OPTIONS_FIELD":
            return { ...state, options: { ...state.options, sc_field: action.data } }
        case "GET_SC_OPTIONS_MENU":
            return { ...state, options: { ...state.options, sc_menu: action.data } }
        default:
            return state
    }
}

export default SetupConfigurationReducer