import React, { lazy, Suspense } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'

import { Provider } from 'react-redux'
import store from './redux/store'

import 'antd/dist/antd.css'
import './index.css'

import reportWebVitals from './reportWebVitals'

const LazyApp = lazy(() => import('./App'))

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Suspense fallback={null}>
        <LazyApp />
      </Suspense>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
)

reportWebVitals()
