
const initialState = {
  data: [],
};

const masterDataMemberReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_MEMBER":
      return { ...state, data: action.data };
    default:
      return state;
  }
};

export default masterDataMemberReducer;
