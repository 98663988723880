import { combineReducers } from 'redux'

import productLaunchingRequest from './ProductLaunchingRequest'
import finalDesign from './FinalDesign'

const prototypeTrialStageReducer = combineReducers({
    productLaunchingRequest,
    finalDesign
})

export default prototypeTrialStageReducer