const initialState = {
    data: {}
}

const manufacturingStageInhouseResultDashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_MS_INHOUSE_RESULT_DASHBOARD_DATA":
            return { ...state, data: action.data }
        default:
            return state
    }
}

export default manufacturingStageInhouseResultDashboardReducer