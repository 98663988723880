const initialState = {
    main: {},
    details: {}
}

const SurkomLaunchingReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_CS_SURKOM_LAUNCHING_MAIN":
            return { ...state, main: action.data }
        case "GET_CS_SURKOM_LAUNCHING_DETAILS":
            return { ...state, details: action.data }
        default:
            return state
    }
}

export default SurkomLaunchingReducer