const initialState = {
    data: {}
}

const getMasterDataCountryReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_MASTER_DATA_COUNTRY_DATA":
            return { ...state, data: action.data }
        default:
            return state
    }
}

export default getMasterDataCountryReducer