const initialState = {
    data: {}
}

const masterDataCompanyReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_MASTER_DATA_COMPANY_DATA":
            return { ...state, data: action.data }
        default:
            return state
    }
}

export default masterDataCompanyReducer